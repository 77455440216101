import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Button, Grid, IconButton } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useMatch } from "react-router-dom";
import Breakpoint from "../../../shared/components/Breakpoint";
import ElevationHeader from "../../../shared/components/ElevationHeader";
import HorizontalFill from "../../../shared/components/HorizontalFill";
import UserAvatar from "../../../shared/components/UserAvatar";
import { useClientContext } from "../../contexts/ClientContext";
import { identityAvatarImageSelector, identityNameSelector } from "../../store/state/user/selectors";
import FeatureBoundary from "../common/FeatureBoundary";
import ClientsSelector from "./ClientsSelector";
import SupportInfoHelp from "./SupportInfoHelp";
import UserMenu from "./UserMenu";
import MainMenu from "./mobile/MainMenu";

interface PageLinkProps {
  route: string;
  title: string;
  highlighted: boolean;
}

const PageLink = ({ route, title, highlighted }: PageLinkProps) => (
  <Grid item pl={2} alignItems="center" display="flex">
    <Button component={Link} to={route} state={{ refresh: true }} color={highlighted ? "primary" : "inherit"}>
      {title}
    </Button>
  </Grid>
);

const Header = () => {
  const avatarImage = useSelector(identityAvatarImageSelector);
  const userName = useSelector(identityNameSelector);

  const { portalSettings } = useClientContext();
  const routeMatch = useMatch("/:client/:pageName/*");
  const pageName = routeMatch?.params["pageName"];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <ElevationHeader>
      <AppBar
        position="sticky"
        sx={(theme) => ({ color: portalSettings?.appBarTextColor || theme.palette.common.white })}
      >
        <Grid
          container
          className="header"
          sx={(theme) => ({
            bgcolor: portalSettings?.appBarColor || theme.palette.common.black,
            py: 1,
            px: 2,
            [theme.breakpoints.down("sm")]: {
              p: ".75rem",
            },
          })}
        >
          <Grid item sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <Breakpoint to="sm">
              <Grid container>
                <IconButton onClick={handleMenu}>
                  <MenuIcon />
                </IconButton>
                <MainMenu anchorEl={anchorEl} onClose={handleClose} />
              </Grid>
            </Breakpoint>
          </Grid>
          <Grid alignItems="center" display="flex">
            <ClientsSelector />
          </Grid>
          <Breakpoint from="sm">
            <Grid container flex={1}>
              <FeatureBoundary required="Dashboards">
                <PageLink title="Dashboard" route="dashboard" highlighted={pageName === "dashboard"} />
              </FeatureBoundary>
              <FeatureBoundary required="Inbox">
                <PageLink title="Inbox" route="inbox/Inbox/p0" highlighted={pageName === "inbox"} />
              </FeatureBoundary>
              <FeatureBoundary required="Fundraising">
                <PageLink title="Fundraising" route="fundraising" highlighted={pageName === "fundraising"} />
              </FeatureBoundary>
              <FeatureBoundary required="PortfolioCompanies">
                <PageLink title="Portfolio" route="portfolio" highlighted={pageName === "portfolio"} />
              </FeatureBoundary>
              <HorizontalFill />
              <Grid item>
                <SupportInfoHelp />
                <IconButton sx={{ ml: 1 }} onClick={handleMenu}>
                  <UserAvatar size="large" userName={userName ?? ""} avatarImage={avatarImage} />
                </IconButton>
              </Grid>
              <UserMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
            </Grid>
          </Breakpoint>
        </Grid>
      </AppBar>
    </ElevationHeader>
  );
};

export default Header;
