import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Container, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import { createApiResponse } from "../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import ExpandableBox from "../../../shared/components/ExpandableBox";
import HtmlPreview from "../../../shared/components/htmlEditor/HtmlPreview";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../shared/hooks/useFetch";
import { api } from "../../api/client";
import { useClientContext } from "../../contexts/ClientContext";
import { availableFundraisingsSelector } from "../../store/state/fundraising/selectors";
import FundraisingDocuments from "./FundraisingDocuments";

const FundraisingPage = () => {
  const { id } = useParams();
  const { clientCode } = useClientContext();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const availableFundraisings = useSelector(availableFundraisingsSelector);

  const getFundraising = useCallback(
    () => (id ? api.fundraising.getFundraising(id) : Promise.resolve(createApiResponse(undefined))),
    [id]
  );

  const [fundraising, fetchFundraisingError, { isFetching }] = useFetch(getFundraising);

  if (!id) {
    <Navigate to="/" />;
  }

  if (fetchFundraisingError) {
    return <DataLoadingFailed title="Failed to load fundraising data" />;
  }

  if (fundraising === undefined || isFetching) {
    return <InlineLoader />;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        pt: isSmallScreen ? 2.5 : 4,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {availableFundraisings.length > 1 && (
        <Button
          variant="outlined"
          color="secondary"
          component={Link}
          to={`/${clientCode}/fundraising`}
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 2.5 }}
        >
          Back
        </Button>
      )}
      <Stack spacing={4} flex={1} width="100%">
        {fundraising.content?.title && <Typography variant="h2">{fundraising.content?.title}</Typography>}
        {fundraising.content?.htmlContent && (
          <Box>
            <ExpandableBox maxHeight={240} containerProps={{ mb: 1 }}>
              <HtmlPreview htmlContent={fundraising.content?.htmlContent} />
            </ExpandableBox>
          </Box>
        )}
        <FundraisingDocuments files={fundraising.files} fundraisingId={fundraising.id} />
      </Stack>
    </Container>
  );
};

export default FundraisingPage;
