import { Theme, useMediaQuery } from "@mui/material";
import { useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import DataGrid from "../../../shared/components/grid/DataGrid";
import { FundraisingFile } from "../../api/types/fundraisingTypes";
import { columnDefinitions, groupingColumnDefinition } from "./fundraisingDocumentsGridDataProvider";

interface Props {
  files: FundraisingFile[];
}

const FundraisingDocumentsGrid = ({ files }: Props) => {
  const gridApiRef = useGridApiRef();
  const includeMoreColumns = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const gridInitialState = useKeepGroupedColumnsHidden({
    apiRef: gridApiRef,
    initialState: {
      rowGrouping: {
        model: ["section"],
      },
      columns: {
        columnVisibilityModel: {
          name: false,
          size: !includeMoreColumns,
          uploadedAt: !includeMoreColumns,
        },
      },
    },
  });

  const columnVisibilityModel = useMemo(() => {
    const model = gridInitialState.columns?.columnVisibilityModel;
    if (!model) {
      return undefined;
    }

    return { ...model, size: !includeMoreColumns, uploadedAt: !includeMoreColumns };
  }, [gridInitialState.columns?.columnVisibilityModel, includeMoreColumns]);

  return (
    <DataGrid<FundraisingFile>
      apiRef={gridApiRef}
      getRowId={(row) => row.fileId}
      columns={columnDefinitions}
      groupingColDef={groupingColumnDefinition}
      rows={files}
      rowHeight={40}
      columnHeaderHeight={includeMoreColumns ? 0 : 36}
      hideFooter
      disableColumnFilter
      disableColumnPinning
      disableColumnSelector
      disableColumnSorting
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      disableMultipleRowSelection
      initialState={gridInitialState}
      isGroupExpandedByDefault={() => true}
      columnVisibilityModel={columnVisibilityModel}
      sx={{
        ".MuiDataGrid-columnHeaderTitle": { color: "text.secondary" },
      }}
      scrollContainerSx={{ height: "auto" }}
    />
  );
};

export default FundraisingDocumentsGrid;
